<template>
    <div class="LogosMover">
        <h1>Koostööpartnerid</h1>
        <div class="ttt" v-for="image in images" :key="image.id">
            <div class="test">
                <img   :src="'https://admin.siivouspuhastus.ee/' + image.logo.url">
            </div>
        </div>
      
    </div>
</template>

<script>
import axios from "axios"
export default {
    name: "LogosMover",
    data () {
        return {
        info: null,
        images: [],
    }
    }, 
    mounted () {
        axios
        .get('https://admin.siivouspuhastus.ee/clients-logos')
        .then(res => {
            (this.images = res.data)
        
        }).catch(
            error => console.log(error)
        )
    }
    }

</script>


<style scoped>
    
    .LogosMover{
        padding-top: 60px;
        padding-bottom: 100px;
        position: relative;
        width: 100%;
        height: 170px;
        background-color: #fff;
    }
   
    .ttt {
        margin-top: 70px;
    }

    .ttt:nth-child(2){
        position: absolute;
        display: flex;
        grid-template-columns: auto;
        top: 25%;
        animation-duration: 13s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: move-1;
    }


    .ttt:nth-child(3){
        animation-delay: 2s;
        position: absolute;
        display: flex;
        grid-template-columns: auto;
        top: 25%;
        animation-duration: 13s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: move-2;
    }

     .ttt:nth-child(4){
        animation-delay: 4s;
        position: absolute;
        display: flex;
        grid-template-columns: auto;
        top: 25%;
        animation-duration: 13s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: move-3;
    }

    .ttt:nth-child(5){
        animation-delay: 6s;
        position: absolute;
        display: flex;
        grid-template-columns: auto;
        top: 25%;
        animation-duration: 13s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: move-4;
    }

    .ttt:nth-child(6){
        animation-delay: 8s;
        position: absolute;
        display: flex;
        grid-template-columns: auto;
        top: 25%;
        animation-duration: 13s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: move-5;
    }

    .ttt:nth-child(7){
        animation-delay: 10s;
        position: absolute;
        display: flex;
        grid-template-columns: auto;
        top: 25%;
        animation-duration: 13s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-name: move-6;
    }

    .ttt:nth-child(n+8){
        display: none;
    }



    .test img{
        width: 100px;
        height: 100px;
    }

    h1{
        text-align: center;
        font-size:35px;
    }



    
    @keyframes move-1 {
        from{left: 90%;}
        to{left: 5%;}
    }


    @keyframes move-2 {
        from{left: 90%;}
        to{left: 5%;}
    }

    @keyframes move-3 {
        from{left: 90%;}
        to{left: 5%;}
    }

    @keyframes move-4 {
        from{left: 90%;}
        to{left: 5%;}
    }

    @keyframes move-5 {
        from{left: 90%;}
        to{left: 5%;}
    }

    @keyframes move-6 {
        from{left: 90%;}
        to{left: 5%;}
    }






     @media only screen and (max-width: 900px) {
         .LogosMover{     
            height: 100px;
        }

        h1{
            z-index: 3;
            position: absolute;
            font-size:30px;
            padding-left: 15%;
        }


        .test img{
            width: 65px;
            height: 65px;
        }


        .ttt:nth-child(2){
            animation-duration: 12s;

        }


        .ttt:nth-child(3){
            animation-delay: 3s;
            animation-duration: 12s;
        }

        .ttt:nth-child(4){
            animation-delay: 6s;
            animation-duration: 12s;
        }

        .ttt:nth-child(5){
            animation-delay: 9s;
            animation-duration: 12s;
        }

        .ttt:nth-child(6){
            display: none;
            animation-name: none;
        }

        .ttt:nth-child(7){
            display: none;
            animation-name: none;
        }



        @keyframes move-1 {
            from{left: 82%;}
            to{left: -12%;}
        }


        @keyframes move-2 {
            from{left: 82%;}
            to{left: -12%;}
        }

        @keyframes move-3 {
            from{left: 82%;}
            to{left: -12%;}
        }

        @keyframes move-4 {
            from{left: 82%;}
            to{left: -12%;}
        }

      



    
        }


</style>