<template>
  <div id="app">
    <PageLoader />
    <Header />
    <router-view/>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/layout/header"
import Footer from "./components/layout/footer"

import fontawesome from "@fortawesome/fontawesome";
import brands from "@fortawesome/fontawesome-free-brands";
import solid from "@fortawesome/fontawesome-free-solid";
import PageLoader from "./components/pageLoader.vue"


fontawesome.library.add(brands, solid);


export default {
  name: 'App',
  components: {
    Header,
    Footer,
    PageLoader,

  }

}
</script>

<style>
    *{
      margin: 0;
      padding: 0;
    }
    body{
      background-color: #DAEEF9;
      width: 100%;
      font-family: 'Fira Sans', sans-serif;
      font-size: 15px;
    }

    
  @media only screen and (max-width: 800px) {
    *{
      max-width: 800px;
    }
    
    body{
      max-width: 800px;
      
    }
  }

</style>
