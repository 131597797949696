<template>
    <div class="loader-wrapper" v-if="isloaded">
        <span class="loader"><span class="loader-inner"></span></span>
    </div>
</template>

<script>
export default {
    data: () =>{
        return{
            isloaded: true
        }
    },
    mounted(){
        this.loader()
    },
    methods: {
        loader(){
            setTimeout( () => {
                this.isloaded = false
            }, 2000)
        }
    }
}
</script>


<style scoped>

    .loader-wrapper {
        z-index: 9;
        width: 100%;
        height: 100%;
        position:fixed;
        top: 0;
        left: 0;
        background-color:#fff;
        display:flex;
        justify-content: center;
        align-items: center;
        animation: fadein  5s;
        -moz-animation: fadein 5s; /* Firefox */
        -webkit-animation: fadein 5s; /* Safari and Chrome */
        -o-animation: fadein 5s; /* Opera */
    }
    .loader {
        display: inline-block;
        width: 40px;
        height: 40px;
        position: relative;
        border: 4px solid #DAEEF9;
        animation: loader 2s infinite ease;
    }
    .loader-inner {
        vertical-align: top;
        display: inline-block;
        width: 100%;
        background-color: #0F3BA7;
        animation: loader-inner 2s infinite ease-in;
        }

    @keyframes loader {
    0% { transform: rotate(0deg);}
    25% { transform: rotate(180deg);}
    50% { transform: rotate(180deg);}
    75% { transform: rotate(360deg);}
    100% { transform: rotate(360deg);}
    }
    @keyframes loader-inner {
    0% { height: 0%;}
    25% { height: 0%;}
    50% { height: 100%;}
    75% { height: 100%;}
    100% { height: 0%;}
    }

    @keyframes fadein {
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:1;
    }
    to {
        opacity: 0;
    }
}
</style>