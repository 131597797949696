<template>
    <div class="view_container">
        <PageLoader/>
        <div class="left_side">
            <div class="ad-con"> 
                <h1>Tööpakkumised</h1>
                <div class="ad-componetns"><Advertisements/></div>
            </div>
        </div>
     
        <div class="right_side">
            <h1>Soovid liituda meie meeskonnaga?</h1>
            <form>
                <div class="box nameInput">
                    <div class="boxheader">
                        <label for="fname">Ees- ja perekonnanimi</label>
                    </div>
                    <input type="text" id="fname" name="name" placeholder="Teie nimi..." required>
                
                </div>
                <div class="box age">
                    <div class="boxheader">
                        <label for="fage">Vanus</label>
                    </div>
                    <input type="text" id="fage" name="age" placeholder="Teie vanus..." required maxlength="3">
                </div>
                <div class="box phone">
                    <div class="boxheader">
                        <label for="fphone">Telefoninumber</label>
                    </div>
                    <input type="text" id="fphone" name="phone" placeholder="Teie number..." required>
                </div>
                 <div class="box email">
                     <div class="boxheader">
                        <label for="femail">E-post</label>
                     </div>
                    <input type="text" id="femail" name="email" placeholder="Teie E-post..." required>
                </div>
                <div class="box driving_licenses">
                    <label for="flicenses">Juhiload</label>
                    <div class="checkbox">
                        <input type="checkbox" id="flicenses" value="1" name="licenses" required >
                        <label for="vehicle1">Jah</label><br>
                        <input type="checkbox" id="flicenses" value="1" name="licenses" required checked>
                        <label for="vehicle1">Ei</label>
                    </div>
                </div>
                <div class="box upload">
                    <input type="file" name="fileToUpload" id="fileToUpload">
                </div>
                <div class="submit">
                    <input type="submit" value="Saada" name="submit">
                </div>
            </form>

        </div>
    </div>
</template>


<script>
import Advertisements from '../components/jobAdvertisement.vue'
import PageLoader from "../components/pageLoader.vue"

export default {
    components: {
        Advertisements,
        PageLoader
    }
}
</script>

<style scoped>

     input{
        width: 50%;
        box-sizing:border-box;
        background:none;
        outline:none;
        resize:none;
        border:0;
        font-family:'Montserrat',sans-serif;
        transition:all .3s;
        border-bottom:2px solid #bebed2;
        
    }

    .left_side{
        width: 100%;
    }

    .ad-con{
        margin-top: 7%;
    }

    .ad-componetns{
        margin-left: 5%;
        margin-right: 5%;
    }

    .left_side h1{
        margin-bottom: 20px;
        text-align: center;
    }

    .right_side{   
        background-color: white;
        width: 100%;   
        height: 100vh;
    }

    .right_side h1{  
        margin-top: 7%;
        text-align: center;
    }

    form{
        text-align: center;
        margin-top: 5%;
        box-sizing:border-box;
    
    }

    form .box{
        margin-top: 8%;
    }

    form .boxheader{
        margin-bottom: 10px;
    }

   

    label{
        font-family:  'Times New Roman';
        
    }

    .checkbox input{
        width: 20px;
        
    }


    .view_container{
        display:flex;
        
    }

  
    .submit input{
        margin-top: 20px;
        height: 40px;
        width: 60px;
        border:2px solid #78788c;
        background:0;color:#5a5a6e;
        cursor:pointer;
    }

     .submit input:hover{
        background:rgba(0, 0, 0, 0.5);
        color:#fff
    }

    .upload input{
        margin-left: 12%;
        border: none;
        width: 200px;
    }


    @media only screen and (max-width: 600px) {
    .view_container{
        display:block;
        
    }

    .upload input{
        margin-left: 0%;    
        width: 100px;
    }
    .left_side{
        height: 100%;
        padding-top: 5%;
    }

    .right_side{
        padding-top: 5%;
        height: 110vh;
    }
   
  }

  @media only screen and (min-width: 600px) and (max-width: 2000px){

    .upload input{
        margin-left: 0%;    
        width: 100px;
    }
    .left_side{
        height: 100%;
        padding-top: 5%;
    }

    .right_side{
        padding-top: 5%;
        height: 110vh;
    }
   
  }
    

</style>