<template>
    <div class="footer" >
        <a target="_blank" href="https://www.facebook.com/Siivous-Puhastus-O%C3%9C-1738889836405674">
            <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDQ3NC4yOTQgNDc0LjI5NCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDc0LjI5NCA0NzQuMjk0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Y2lyY2xlIHN0eWxlPSJmaWxsOiMzQTVBOTg7IiBjeD0iMjM3LjExMSIgY3k9IjIzNi45NjYiIHI9IjIzNi45NjYiLz4NCjxwYXRoIHN0eWxlPSJmaWxsOiMzNDUzODc7IiBkPSJNNDA0Ljc0Miw2OS43NTRjOTIuNTQxLDkyLjU0MSw5Mi41NDUsMjQyLjU4Ni0wLjAwNCwzMzUuMTM0DQoJYy05Mi41NDUsOTIuNTQxLTI0Mi41OTMsOTIuNTQxLTMzNS4xMzQsMEw0MDQuNzQyLDY5Ljc1NHoiLz4NCjxwYXRoIHN0eWxlPSJmaWxsOiMyRTRENzI7IiBkPSJNNDcyLjU0MywyNjMuNjU2TDMwMS4xMjksOTIuMjM4bC04OC45OTgsODguOTk4bDUuMzAyLDUuMzAybC01MC42NzEsNTAuNjY3bDQxLjQ3NCw0MS40NzQNCglsLTUuNDU1LDUuNDUybDQ0LjkwMSw0NC45MDFsLTUxLjc2NCw1MS43NjRsODguNDI5LDg4LjQyOUMzODQuMDY1LDQ0OS4wNDUsNDYxLjAzNywzNjYuMjU1LDQ3Mi41NDMsMjYzLjY1NnoiLz4NCjxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNMTk1LjY4MiwxNDguOTM3YzAsNy4yNywwLDM5Ljc0MSwwLDM5Ljc0MWgtMjkuMTE1djQ4LjU5OGgyOS4xMTV2MTQ0LjQwMmg1OS44MDhWMjM3LjI3Nmg0MC4xMzQNCgljMCwwLDMuNzYtMjMuMzA3LDUuNTc5LTQ4Ljc4MWMtNS4yMjQsMC00NS40ODUsMC00NS40ODUsMHMwLTI4LjI3NiwwLTMzLjIzMWMwLTQuOTYyLDYuNTE4LTExLjY0MSwxMi45NjUtMTEuNjQxDQoJYzYuNDM2LDAsMjAuMDE1LDAsMzIuNTg3LDBjMC02LjYyMywwLTI5LjQ4MSwwLTUwLjU5MmMtMTYuNzg2LDAtMzUuODgzLDAtNDQuMzA2LDBDMTk0LjIwMSw5My4wMjgsMTk1LjY4MiwxNDEuNjcxLDE5NS42ODIsMTQ4LjkzNw0KCXoiLz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K" />
        </a>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
    .footer{
        position: fixed;
        width: 100px;
        height: 70px;
        bottom: 0.5%;
        color: white;
        z-index: 3;
        font-size: 20px;
    }

    .footer img{
        width: 40px;
        position: absolute;
        padding: 20px;
        
        
    }

    
</style>