<template>
    <div class="main-container">
        <PageLoader/>
        <h1>Tehtud tööd</h1>
        <div class="grid-container">
          <div v-for="object in objects" :key="object.id">
              <div class="object ele">
                <img :src="'https://admin.siivouspuhastus.ee/' + object.img.url">
              </div>
              <div class="img-text">{{object.text}}</div>
          </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import PageLoader from "../components/pageLoader.vue"
export default {
    components: {
      PageLoader 
    },
    data () {
    return {
     info: null,
     objects: [],
   }
  }, 
   mounted () {
    axios
      .get('https://admin.siivouspuhastus.ee/Objects')
      .then(res => {
        (this.objects = res.data)
        console.log(res.data)

      
      }).catch(
          error => console.log(error)
      )
  }
}
</script>

<style scoped>

  h1{
    margin-top: 6%;
    margin-left:15%;
  }
  .grid-container{
    position: relative;
    display: grid;
    grid-template-columns: 500px 500px 500px ;
    margin: 2% 15% 2% 15%; 
    gap: 8%;
  }

  .img-text{
    padding-top: 20px;

    text-align: center;
  }

  .ele{
    height: 500px;
    width: 500px;
    overflow: hidden;
  }

  .object img{
    object-fit: cover;
     height: 500px;
      width: 500px;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -ms-transition: all 1s ease;
        transition: all 1s ease;

      border-radius: 10px;
  }


   .object img:hover{
     width: 600px;
     height: 600px;
   }

  @media only screen and (max-width: 600px) {

    h1{
      margin-top: 10%;
      margin-left: 15%;
    }
    .grid-container{
      position: relative;
      display: grid;
      grid-template-columns: auto;
      margin: 2% 8%; 
      gap: 3%;
    }

    .ele{
      height: 300px;
      width: 300px;
      overflow: hidden;
    }

    .object img{
     height: 300px;
      width: 300px;
    }


    .object img:hover{
      width: 350px;
      height: 350px;
    }

    .img-text{
      padding-top: 20px;
      width: 300px;
      text-align: center;
    }
  }

  @media only screen and (min-width: 600px) and (max-width:2000px) {
    h1{
      margin-top: 10%;
      margin-left: 15%;
    }
 
    .grid-container{
      grid-template-columns: auto auto;
      margin: 2% 15% 2% 15%; 
      gap: 10%;
      column-gap: 15%;
      margin-bottom: 12%;
    }

    .ele{
      height: 400px;
      width: 400px;
      overflow: hidden;
    }

    .object img{
     height: 400px;
      width: 400px;
    }


    .object img:hover{
      width: 500px;
      height: 500px;
    }

    .img-text{
      padding-top: 20px;
      width: 400px;
      text-align: center;
    }
  }
  
  

</style>