<template>
    <div class="jobsContainer">
      <div v-for=" job in advertisements" :key="job.id">
            <div class="jobAd">
                <div><h1>{{job.header}}</h1></div>
                <div><p>{{job.content}}</p></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
     name: "jobAdvertisement",
    data () {
        return {
        info: null,
        advertisements: [],
        }
    }, 
    mounted () {
        axios
        .get('https://admin.siivouspuhastus.ee/jobs')
        .then(res => {
            (this.advertisements = res.data)
        
        }).catch(
            error => console.log(error)
        )
    }
}
</script>

<style scoped>


    .jobsContainer{
        display: grid;
        grid-template-columns: auto;
    }

    .jobAd{
        border: 2px solid white;
        margin-bottom: 30px;
        padding: 20px;
        background-color: #0F3BA7;
        border-radius: 10px;
    }
    h1{
        color: #DAEEF9;
        margin-bottom: 10px;
    }

    p{
        color: #DAEEF9;
        font-size: 19px;  
    }
</style>