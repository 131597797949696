import Vue from "vue";
import VueRouter from "vue-router";
import Contact from "../views/Contact.vue";
import Home from "../views/Home.vue";
import Services from "../views/Services.vue";
import Clients from "../views/Clients.vue";
import jobAdvertisement from "../views/jobAdvertisement.vue";
import completedWork from "../views/completedWork.vue";

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/contact",
      name: "contact",
      component: Contact,
    },
    {
      path: "/services",
      name: "services",
      component: Services,
    },
    {
      path: "/clients",
      name: "clients",
      component: Clients,
    },
    {
      path: "/jobAdvertisement",
      name: "jobAdvertisement",
      component: jobAdvertisement,
    },
    {
      path: "/completedWork",
      name: "completedWork",
      component: completedWork,
    },
  ],
});
