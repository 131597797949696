<template>
    <div class="main-container">
        
        <PageLoader/>
        <div class="data-container">
            <div class="clientsCount">
                <p>56+</p>
                <h1>püsiklienti</h1>
            </div>
            <div class="objectsCount">
                <p>192+</p>
                <h1>objekti</h1>
            </div>
            <div class="year">
                <p>15</p>
                <h1>aastat kogemust</h1>
            </div>
        </div>
        <div  class="header"><h1>Leia endale sobiv teenus</h1></div>
        <div class="service-container">
          <div id="service-card" v-for="(servic, index) in services" :key="servic.id" >
          <div class="service-box">
             <img  :src="'https://admin.siivouspuhastus.ee/' + servic.img.url">
             <div class="box">
                
                <h1>{{servic.Header}}</h1>
                
                 <SlideUpDown :active="active && index == test" :duration="500">
                    
                    <p>{{servic.text}}</p>
                 </SlideUpDown>
              </div>
              <div class="slide-button" @click="toggleShowOptions(index)" >
                  Vaata rohkem
                  <img src='../assets/down-arrow.svg' />
                
              </div>
          </div>
          </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";
import PageLoader from "../components/pageLoader.vue"
import SlideUpDown from 'vue-slide-up-down'
export default {
    
    components: {
      PageLoader,
      SlideUpDown 
    },
    data () {
    return {
     info: null,
     services: [],
     active: false,
     test: null,
   }
  }, 
   mounted () {
    axios
      .get('https://admin.siivouspuhastus.ee/services')
      .then(res => {
        (this.services = res.data),
        console.log(res.data[0].img)
      
      }).catch(
          error => console.log(error)
      )
  },
  methods: {
   toggleShowOptions(index) {
        console.log(index)
        this.test = index;
        this.active = !this.active;
        console.log(this.active)
      },
  },
}
</script>


<style scoped>


  .data-container{
    text-align: center;
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto;
    background-color:#fff;
    height: 200px;
    margin: 5% 6%;
    margin-bottom: 2%;
  }
  .data-container div{
      margin-top: 3%;
  }

  .data-container p{
    font-size: 100px;
  }
  
  .clientsCount p{
    color: #0F3BA7;;
  }

  .objectsCount p{
    color: #0F3BA7;;
  }

  .year p{
    color:#0F3BA7;;
  }

  .data-container h1{
    margin-top: 20px;
    font-size: 20px;
  }

  .service-container{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr  ;
    row-gap: 4ch;
    justify-items: center;
    margin-left: 5%;
    margin-right: 5%;
    padding: 3%;
    margin-top: 2%;
  }

 

  .service-box{
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
  }
  




  .header{
    position: relative;
    display: grid;
    place-items: center;
      
  }

  .header h1{
    color: white;
    padding: 15px;
    width: 70%;
    text-align: center;
    border-radius: 50px;
    background-color:  #0F3BA7;
    
  }

  .box{
    padding: 10px;
    max-width: 300px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }

  .box h1{
    margin-bottom: 20px;
  }

  .slide-button{
    position: relative;
    padding-top: 10px;
    padding-bottom: 35px;
    text-align: center;
    display: inline-block;
    width: 100%;
    height: 20px;
    background-color: #0F3BA7;
    color:#fff;
  }

  .slide-button img{
    position: absolute;
    left: 0;
    top:-130px;
    transform: scale(0.03);
    
  }

  .slide-button img svg path{
    fill: white;
  }


  /* .box{
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
    height: 100%;
    position: fixed;
    z-index: 1;
    display: none;
    width: 100%;
    top: 0;
    left: 0;
  } */

  img{
    width: 500px;
    height: 350px;
    border-radius: 10px;
    object-fit: cover;

  }

  /* .closebtn {
      display: block;
      position: absolute;
      top: 20px;
      right: 45px;
      font-size: 60px;
      color: white;
  } */


  @media only screen and (max-width: 800px) {
    .service-container{
      grid-template-columns: auto;
      margin: 0%;
      padding: 0%;
    }

     img{
      width: 100%;
      height: 350px;
      border-radius: 10px;
      object-fit: cover;
    }

    .data-container{
      margin-bottom: 0%;
      margin: 0%;
      height: 220px;
      padding-left: 15px;
      column-gap: 8px;
    }

    .data-container p{
      font-size: 70px;
    }

    .data-container div{
      margin-top: 45px;
    }


    .header{
      left: 0;
    }

  .header h1{
    
    padding: 0px;
    width: 100%;
    border-radius: 50px;
    font-size: 35px;
    margin-top: 10px;
    margin-bottom: 10px;

    
    }

  }


  @media only screen and (min-width: 800px)  and (max-width: 1900px){
    .service-container{
      grid-template-columns: auto auto ;
      margin: 2%;
      padding: 2%;
      column-gap: 2rem;
    }

     img{
      width: 100%;
      max-width: 700px;
      height: 350px;
      border-radius: 10px;
      object-fit: cover;
    }

    .data-container{
      margin-bottom: 0%;
      margin: 2%;
      height: 250px;
      padding-left: 15px;
      column-gap: 8px;
    }

    .data-container p{
      font-size: 90px;
    }

    .data-container div{
      margin-top: 70px;
    }
  }

 


 

</style>