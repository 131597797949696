<template>
   <div class="container">
        <PageLoader/>
        <div v-for=" client in clients" :key="client.id">
            <div class="client-header"><h1>{{client.header}}</h1></div>
            <div class="client-box">
                <img  :src="'https://admin.siivouspuhastus.ee/' + client.test.url" v-bind:style="{ 'background-color':'rgba(0,0,0,0.9)'}">
            </div>
            <div class="client-text"><p>{{client.text}}</p></div>
          
        </div>
    </div>
</template>

<script>
import axios from "axios";
import PageLoader from "../components/pageLoader.vue"
export default {
     components: {
        PageLoader,
      
  },
    data(){
        return {
            info: null,
            clients: [],
             color: 'lightgreen',
        }
    },

    mounted(){
     axios
      .get('https://admin.siivouspuhastus.ee/clients')
      .then(res => {
        (this.clients = res.data),
        (this.info = res.data[0].url)
        console.log(res.data[0].test.url)
      
      }).catch(
          error => console.log(error)
      )
  }
}
</script>

<style scoped>
   
    .container{
        justify-items: center;
        display: grid;
        margin: 5% 15%;
        row-gap: 10ch;
        grid-template-columns: auto auto auto;
        
        
    }
    .container img{
        width: 320px;
        height: 300px;
        border-radius: 15px;
        object-fit: cover;
        
    }
    
    .client-header{
       z-index: 1;
        display: none;
       text-align: center;
    }

    .client-header h1{
       margin-top: 250px;
       margin-left: 100px;
       color: #000; 
       
    }

    .client-text{
        width: 100%;
        margin-top: 10%;
        background-color: #0F3BA7;
        border-radius: 15px;
    }
    .client-text p{
        padding: 30px;
        margin-left: 0px;
        font-size: 20px;
        color: #DAEEF9;
        
    }

    .client-box{
        border-radius: 15px;
    }

    .client-box p{
        color: #DAEEF9;
        padding: 20px;
    }


    @media only screen and (max-width: 600px) {
        .container{
            grid-template-columns: auto;
            margin: 13% 5%;
            row-gap: 20px;
         }

         .container img{
            width: 280px;
            height: 300px;
        }
        .client-text{
            width: 100%;
        }

    }

     @media only screen and (min-width: 600px) and (max-width: 1300px) {
        .container{
            grid-template-columns: auto auto auto;
            margin: 10% 5%;
            row-gap: 10%;
         }

         .container img{
            width: 280px;
            height: 300px;
        }
        .client-text{
            width: 100%;
        }

    }
</style>