<template>
    
    <header class="Header">
    <a href="/"><img src="@/assets/logo2.png"></a>
        <div class="topnav">
            <div class="menu-button">
                <a v-on:click="openNav()"><i class="fa fa-bars"  aria-hidden="true"></i></a>
            </div>
            <div id="myNav" class="overlay">
                <a href="javascript:void(0)" class="closebtn" v-on:click="closeNav()">&times;</a>
                <div class="overlay-content">
                    <router-link   to="/"  id="style1-link" @click.native="closeNav()">Avaleht</router-link>
                    <router-link   to="" id="style2-link">Teenused
                        <div class="sub-nav">
                            <router-link  to="services" @click.native="closeNav()">Pakutavad teenused</router-link>
                            <router-link  to="completedWork" @click.native="closeNav()">Tehtud tööd</router-link>
                        </div>
                    </router-link>    
                    <router-link  to="contact" id="style1-link" @click.native="closeNav()">Kontakt</router-link>
                    <router-link  to="clients" id="style2-link" @click.native="closeNav()">Kliendid</router-link>
                    <router-link  to="jobAdvertisement" id="style1-link" @click.native="closeNav()">Tule tööle!</router-link>
                </div>
            </div>
        </div>

    </header>
</template>

<script>




export default {
    name: "Header",
    data(){
        return{
            state: false
        }
    },
    methods: {

        openNav(){
        this.state = true
        document.getElementById("myNav").style.width = "100%";
        },

        closeNav() {
        document.getElementById("myNav").style.width = "0%";
        },
        
        
    },//methods
  
}

</script>

<style scoped>
    .menu-button{
        display: none;
    }

    img{
        margin-left: 10%;
        height: 200px;
        width: 200px;
    }
    .Header{
        z-index: 1;
        position: relative;
        background-color:#fff;
        height: 100px;
        width: 100%;
        
    }


    #style2-link{
        background-color: #DAEEF9;
        border-bottom:  6px solid blue;
    }
    #style1-link{
        border-bottom:  6px solid #DAEEF9;
    }


    /*sub nav*/
    .sub-nav {
        display: none;
        position: absolute;
        width: 73%;
        z-index: 1;
        margin-left: 107px;
        top: 50px;
  
    }

    .sub-nav a{
        font-size: 15px;
        text-decoration: none;
    }

    a:hover  .sub-nav{
        background-color: #fff;
        display: block;
    }

    /* nav menu*/
    .overlay a:hover, .overlay a:focus {
        color: #f1f1f1;
    }

    .overlay .closebtn{
        display: none;
    }

    .overlay-content {
        position: absolute;
        right: 19%;
        top: 40%;
    }
    
    .overlay a {
        padding: 20px;
        font-size: 25px;
        color: #818181;
        transition: 0.3s;
        text-decoration: none;
    }



    @media only screen and (max-width: 800px) {
        /* mobile view nav menu */
        .overlay a {font-size: 20px}
        .overlay .closebtn {
        font-size: 40px;
        top: 15px;
        right: 20px;
        }

        #style2-link{
            background-color: inherit;
            border-bottom: none;
        }

        #style1-link{
        border-bottom: none;
        }

        .overlay .closebtn {
            display: block;
            position: absolute;
            top: 20px;
            right: 45px;
            font-size: 60px;
        }

        .overlay {
            height: 100%;
            width: 0;
            position: fixed;
            z-index: 2;
            top: 0;
            left: 0;
            background-color: rgb(0,0,0);
            background-color: rgba(0,0,0, 0.9);
            overflow-x: hidden;
            transition: 0.5s;
        }

        .overlay a {
            padding: 8px;
            text-decoration: none;
            font-size: 36px;
            color: #818181;
            display: block;
            transition: 0.3s;
        }

        .overlay-content {
            position: relative;
            top: 25%;
            width: 100%;
            text-align: center;
            margin-top: 30px;
            right: 0%;
        }

        /* */
        .Header{
            height: 70px;
            z-index:2;
        }

        img{
            position: absolute;
            height: 120px;
            width: 120px;
            margin-left: 30px;
        }

        .menu-button {
            cursor: pointer;
            font-size:2rem;
            padding:1.3rem 1.9rem;
            position:fixed;
            right:0;
        }

        .menu-button a{
            font-size:2rem;
        }

        .menu-button{
            display: block;
        }

        .topnav {
            overflow: hidden;
            position: relative;
            background-color: white;
        }

        .sub-nav{
           position: relative;
           width: 100%;
           margin-left: 0px;
           top: 0px;
        }

        a:hover  .sub-nav{
            background-color: inherit;
        }
    
        
    }

     @media only screen and (min-width: 801px) and (max-width: 1200px) {
        img{
            margin-left: 4%;
            height: 150px;
            width: 150px;
        }

        .sub-nav{
            display: none;
        }
            .overlay-content {
            right: 2%;
        }   

        .overlay a {
            padding: 10px;
        
        }
    
        
    
         

    }

    @media only screen and (min-width: 1200px) and (max-width: 2000px) {
        img{
            margin-left: 10%;
            height: 200px;
            width: 200px;
        }

        .sub-nav{
            display: none;
        }
        .overlay-content {
            right: 7%;
        }   
    
        
    
         

    }

 
</style>