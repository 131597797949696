<template>
    <div class="page-container">
        <PageLoader/>
        <div class="home-pic">
            <a @click="scrollToElement">
                <svg class="arrows">
						<polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
                        <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
                        <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>	
			</svg>
            </a>
        </div>
        <div class="mover">
            <div class="left"></div>
            <div class="right"></div>
            <div class="center"><LogosMover/></div>
            
            
        </div>
        <div id="home-bottom" class="bottom-container">
              <p>Siivous Puhastus OÜ tegutseb juba aastast 2006. Esimestel aastastel oli põhiline rõhk eripuhastustööde teostamisel Ida-Virumaal.
                   Aastast 2008 laiendasime oma tegevusala hoolduskoristusele, alates sellest oleme pannud põhilise rõhu hoolduskoristusteenuse pakkumisele.
                    Kui vajate puhastusteenuste valdkonnas kindlat partnerit, kes tagaks puhtuse nii kodus, kontoris kui laos, siis võtke meiega ühendust ja leiame Teile parima lahenduse. 
                    <br>Siivous Puhastus OÜ juhatuse liige Jaano Tukkija.
                </p>
        </div>
        
        
    </div>
</template>

<script>
import LogosMover from '../components/logosMover.vue'
import PageLoader from "../components/pageLoader.vue"


export default {
    components: {
        LogosMover,
        PageLoader,
        
  },
  methods:{
      scrollToElement() {
        const el = document.getElementById("home-bottom");

    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      el.scrollIntoView({behavior: 'smooth'});
    }
  }
  }
}
</script>

<style scoped>
        .arrows {
        width: 80px;
        height: 90px;
        position: absolute;
        left: 50%;
        margin-left: -30px;
        bottom: 1%;

        
    }


    .arrows polygon {
        
        fill: transparent;
        animation: arrow 6s infinite;
        -webkit-animation: arrow 6s infinite; 
    }

    @keyframes arrow
    {
    0% {opacity:0}
    40% {opacity:1}
    80% {opacity:0}
    100% {opacity:0}
    }

    @-webkit-keyframes arrow /*Safari and Chrome*/
    {
    0% {opacity:0}
    40% {opacity:1}
    80% {opacity:0}
    100% {opacity:0}
    }

    .arrows polygon.arrow-top {
        fill: white;
        animation-delay:-1s;
        -webkit-animation-delay:-1s; /* Safari 和 Chrome */
    }

    .arrows polygon.arrow-middle  {
        fill: #DAEEF9;
        stroke-width: 2px;	
        animation-delay:-0.5s;
        -webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
    }

    .arrows polygon.arrow-bottom  {
        fill: #0F3BA7;
        stroke-width: 5px;		
        animation-delay:0s;
        -webkit-animation-delay:0s; /* Safari 和 Chrome */
    }



    .home-text{
        height: 35vh;
        display:flex;
        
    }

    .bottom-container p{
        text-align: center;
        margin-top: 8.5%;
        left: 30%;
        position: absolute;
        color: #fff;
        width: 900px;
        font-size: 24px;
    }

    .page-container{
        display: block;
    }


    .home-pic{
        background: url("../assets/IMG_1692.jpg") no-repeat center center;
        background-size: cover;
        -webkit-background-size: cover;
         -moz-background-size: cover;
         -o-background-size: cover;
         background-size: cover;
        object-fit: cover;
        height: 95vh;
    }

    .mover{
            width: 100%;
    }

     .left{
            z-index: 1;
            position: absolute;
            float: left;
            background-color: white;
            height: 330px;
            width: 20%;
        }

        .right{
            z-index: 1;
            position: absolute;
            right: 0;
            background-color: white;
            height: 330px;
            width: 20%;
        }

   
  

   

    .bottom-container{
        height: 577px;
        background-color: #0F3BA7;
        clip-path: polygon(44% 0%, 100% 0, 100% 29%, 80% 48%, 62% 100%, 0 100%, 20% 34%);
        width: 100%;
    }



    @media only screen and (max-width: 600px) {
        .home-pic{
            position: relative;
            height: 92vh;
        }

        .mover{
            width: 100%;
        }

        .bottom-container p{
            margin-top: 4%;
            left: 0%;
            width: 95%;
            font-size: 19px;
            padding: 0% 2%;
        }


        
        .left{
            z-index: 1;
            position: absolute;
            float: left;
            background-color: white;
            height:260px;
            width: 85px;
        }

        .right{
            z-index: 1;
            position: absolute;
            right: 0;
            background-color: white;
            height:260px;
            width: 85px;
        }

        .arrows {
            bottom: 2%;
         }

    
       

        .bottom-container{
            position: relative;
            display: block;
            height: 56vh;
            clip-path: none;
            width: 100%;
         }
        
    }

    @media only screen and (min-width: 950px)  and (max-width: 1700px) {
        .bottom-container{
            clip-path: polygon(44% 0%, 100% 0, 100% 49%, 75% 65%, 62% 100%, 0 100%, 9% 34%);
        }
        .bottom-container p{
            text-align: center;
            margin-top: 15%;
            left: 16%;
            position: absolute;
            color: #fff;
            width: 70%;
            font-size: 24px;
        }
    }


     @media only screen and (min-width: 600px)  and (max-width: 950px) {
        .bottom-container{
            clip-path: polygon(44% 0%, 100% 0, 100% 49%, 87% 65%, 76% 100%, 0 100%, 9% 23%);
        }
        .bottom-container p{
            text-align: center;
            margin-top: 18%;
            left: 14%;
            position: absolute;
            color: #fff;
            width: 70%;
            font-size: 24px;
        }

        .left{
            z-index: 1;
            position: absolute;
            float: left;
            background-color: white;
            height:260px;
            width: 85px;
        }

        .right{
            z-index: 1;
            position: absolute;
            right: 0;
            background-color: white;
            height:260px;
            width: 85px;
        }

    }
</style>